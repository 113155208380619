import React, {Suspense, lazy} from 'react';
import './App.scss';
import {
  BrowserRouter as Router,
  Switch,
  Route,
} from "react-router-dom";

import 'lazysizes';
import 'lazysizes/plugins/parent-fit/ls.parent-fit';

const Main = lazy(() => import("./pages/Main/Main"));
const Regions = lazy(() => import("./pages/Regions/Regions"));
const ThankYou = lazy(() => import("./pages/ThankYou/ThankYou"));

export const App = () => {
  return (
    <div className="App">
      <Router>
        <Suspense fallback={<div></div>}>
          <Switch>
            <Route exact path={`${process.env.REACT_APP_URL}:region/:lang?/thank_you`} component={ThankYou} />
            <Route exact path={`${process.env.REACT_APP_URL}:region/:lang?`} component={Main} />
            <Route exact path={`${process.env.REACT_APP_URL}/`} component={Regions} />
          </Switch>
        </Suspense>
      </Router>
    </div>
  );
};
